const LicenseTypeNormalizer = (data, options, currency = null) => {
  return new Promise((resolve, reject) => {
    let licenseTypeValid = false;

    for (const idx in data) {
      for (const pIdx in options) {
        if (data[idx].user === options[pIdx].key) {
          const keys = Object.keys(data[idx]);

          licenseTypeValid = true;

          for (const kIdx in keys) {
            options[pIdx][keys[kIdx]] = data[idx][keys[kIdx]];
          }

          options[pIdx].isSale = {
            GBP: data[idx].prices.price['GBP'] < data[idx].prices.retailPrice['GBP'],
            EUR: data[idx].prices.price['EUR'] < data[idx].prices.retailPrice['EUR'],
            USD: data[idx].prices.price['USD'] < data[idx].prices.retailPrice['USD']
          };
        }

        if (data[idx].user === options[pIdx].key) {
          options[pIdx].price = data[idx].prices.price;
          options[pIdx].retailPrice = data[idx].prices.retailPrice;
          options[pIdx].currency = data[idx].currency;
          options[pIdx].prices = data[idx].prices;
        }

        if (
          data[idx].user === options[pIdx].key.replace('monthly', 'yearly') &&
          options[pIdx].key !== options[pIdx].key.replace('monthly', 'yearly')
        ) {
          options[pIdx].yearly_plan = data[idx].user;
          options[pIdx].yearly_price = data[idx].prices.price;
          options[pIdx].yearly_term = data[idx].term;
          options[pIdx].yearly_retail_price = data[idx].prices.retailPrice;
        }

        if (
          data[idx].user === options[pIdx].key.replace('yearly', 'monthly') &&
          options[pIdx].key !== options[pIdx].key.replace('yearly', 'monthly')
        ) {
          options[pIdx].monthly_plan = data[idx].user;
          options[pIdx].monthly_price = data[idx].prices.price;
          options[pIdx].monthly_term = data[idx].term;
          options[pIdx].monthly_retail_price = data[idx].retailPrice;
        }
      }
    }

    if (licenseTypeValid) {
      resolve(options);
    } else {
      reject(null);
    }
  })
};

export default LicenseTypeNormalizer;
