import Request from '../system/Request';

const License = {
  get: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/users/me/licenses', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  updateAssignee: (licenseReference, assigneeReference) => {
    return new Promise((resolve, reject) => {
      (new Request()).put(`/api/users/me/licenses/${licenseReference}/assignee`, JSON.stringify({
        'assignee': assigneeReference
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  removeAssignee: (licenseReference) => {
    return new Promise((resolve, reject) => {
      (new Request()).delete(`/api/users/me/licenses/${licenseReference}/assignee`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  }
};

export default License;
