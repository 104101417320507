import React from 'react';
import { Button } from 'react-bootstrap';
import FontAwesomeIcon from '../FontAwesomeIcon';

import styles from './styles.module.scss';

const WhatsApp = () => {
  const onOpenWhatsApp = () => {
    window.open(
      'https://api.whatsapp.com/send?phone=' + process.env.REACT_APP_PHONE_NUMBER,
      '',
      'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=400'
    )
  };

  return <div className={styles.container}>
    <Button
      onClick={() => onOpenWhatsApp()}
      tabIndex={-1}
      size={'lg'}
      variant={'success'}
      className={'fs-7 white-text d-flex justify-content-center align-items-center'}
    >
      <FontAwesomeIcon type={'brand'} icon={'whatsapp'} className={'fs-5 me-2'} />
      WhatsApp
    </Button>
  </div>;
};

export default WhatsApp;
