import React, { createContext, useEffect, useState } from 'react'
import InitOrderStateData from '../data/InitOrderStateData';
import Cache from '../system/Cache';

export const OrderContext = createContext({
  state: InitOrderStateData,
  dispatch: () => null
});

export const OrderProvider = ({ children }) => {
  const cache = new Cache();

  const [orderState, setOrderState] = useState(() => {
    const localOrderState = cache.get('order');

    if (null === localOrderState) {
      const initOrderState = InitOrderStateData;

      if ('yes' === process.env.REACT_APP_VAT_ENABLED) {
        initOrderState.vat = true;
      }

      return initOrderState;
    }

    return localOrderState;
  });

  const [params] = useState(() => {
    const initParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    return initParams;
  });

  const dispatchOrder = (order) => {
    cache.set('order', order);
    setOrderState(order);
  };

  useEffect(() => {
    const cache = new Cache();

    // Check if user came from app
    if (params.app) {
      const data = window.atob(params.app);

      // Check data is a string before we parse it
      if ('string' === typeof data) {
        const user = JSON.parse(data);

        // Check user is an object
        if ('object' === typeof user) {
          // Let's check order matches email before we update
          if (orderState.email !== user.email) {
            orderState.first_name = user.forename;
            orderState.last_name = user.surname;
            orderState.email = user.email;
            orderState.company_name = user.company;
            orderState.company_country = user.country;

            cache.set('order', { ...orderState });
            setOrderState({ ...orderState });
          }

          setTimeout(() => {
            window.location.replace(window.location.pathname);
          });
        }
      }
    }
  }, [params, orderState, setOrderState]);

  return <OrderContext.Provider value={[ orderState, dispatchOrder ]}>
    { children }
  </OrderContext.Provider>;
};
