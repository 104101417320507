class CryptoService {
  type = 'RSA-OAEP';
  sha = 'SHA-256';
  mode = 'AES-GCM';
  length = 256;
  ivLength = 12;

  privateKeyType = 'PBKDF2';
  privateKeyLength = 2048;
  dataLength = (
    this.privateKeyLength === 1024 ? 40 :
      this.privateKeyLength === 2048 ? 90 :
        this.privateKeyLength === 4096 ? 220 : 0
  );

  interations = 1000;

  salt;
  passphrase;

  chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  lookup = new Uint8Array(256);

  constructor() {
    for (let i = 0; i < this.chars.length; i++) {
      this.lookup[this.chars.charCodeAt(i)] = i;
    }
  }

  arrayBufferToString(arrayBuffer) {
    return String.fromCharCode.apply(null, new Uint16Array(arrayBuffer));
  }

  stringToArrayBuffer(string) {
    const buf = new ArrayBuffer(string.length * 2);
    const bufView = new Uint16Array(buf);

    for (let i = 0, strLen = string.length; i < strLen; i++) {
      bufView[i] = string.charCodeAt(i);
    }

    return buf;
  }

  hex(buff) {
    return [].map.call(new Uint8Array(buff), b => ('00' + b.toString(16)).slice(-2)).join('');
  }

  encode64ArrayBuffer(arrayBuffer) {
    let base64 = '';

    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.length;

    for (let i = 0, l = len; i < l; i += 3) {
      base64 += this.chars[bytes[i] >> 2];
      base64 += this.chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
      base64 += this.chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
      base64 += this.chars[bytes[i + 2] & 63];
    }

    if ((len % 3) === 2) {
      base64 = base64.substring(0, base64.length - 1) + '=';
    } else if (len % 3 === 1) {
      base64 = base64.substring(0, base64.length - 2) + '==';
    }

    return base64;
  }

  decode64ArrayBuffer(base64) {
    const len = base64.length;
    let p = 0;

    let encoded1;
    let encoded2;
    let encoded3;
    let encoded4;
    let bufferLength = base64.length * 0.75;

    if (base64[base64.length - 1] === '=') {
      bufferLength--;

      if (base64[base64.length - 2] === '=') {
        bufferLength--;
      }
    }

    const arrayBuffer = new ArrayBuffer(bufferLength);
    const bytes = new Uint8Array(arrayBuffer);

    for (let i = 0; i < len; i += 4) {
      encoded1 = this.lookup[base64.charCodeAt(i)];
      encoded2 = this.lookup[base64.charCodeAt(i + 1)];
      encoded3 = this.lookup[base64.charCodeAt(i + 2)];
      encoded4 = this.lookup[base64.charCodeAt(i + 3)];

      bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
      bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
      bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
    }

    return arrayBuffer;
  }

  hash(algo, str) {
    return window.crypto.subtle.digest(algo, this.stringToArrayBuffer(str));
  }

  setSalt(salt) {
    this.salt = btoa(salt);
    return this;
  }

  getSalt() {
    return this.salt;
  }

  setPassphrase(passphrase) {
    return new Promise((resolve, reject) => {
      this.hash(this.sha, btoa(passphrase)).then((hashed) => {
        this.passphrase = this.encode64ArrayBuffer(hashed);

        resolve();
      }, (error) => {
        console.log(error);
        reject('Failed to set passphrase');
      });
    });
  }

  getPassphrase() {
    return this.passphrase;
  }

  encryptKey(privateKey) {
    return new Promise((resolve, reject) => {
      window.crypto.subtle.importKey(
        'raw',
        this.stringToArrayBuffer(this.getPassphrase()),
        this.privateKeyType,
        false,
        ['deriveBits', 'deriveKey']
      ).then((key) => {
        return window.crypto.subtle.deriveKey({
          name: this.privateKeyType,
          hash: this.sha,
          salt: this.stringToArrayBuffer(this.getSalt()),
          iterations: this.interations
        }, key, {
          name: this.mode,
          length: this.length
        }, false, ['encrypt', 'decrypt']);
      }).then((webKey) => {
        const algoIv = window.crypto.getRandomValues(new Uint8Array(this.ivLength));

        window.crypto.subtle.encrypt({
            name: this.mode,
            length: this.length,
            iv: algoIv
          },
          webKey,
          this.stringToArrayBuffer(JSON.stringify(privateKey))
        ).then((cipherText) => {
          resolve({
            cipherText: this.encode64ArrayBuffer(cipherText),
            iv: algoIv
          });
        }, (error) => {
          console.log(error);
          reject('Encrypt Private Key Failed');
        });
      }, (error) => {
        console.log(error);
        reject('Encrypt Private Key Failed');
      });
    });
  }

  decryptKey(data) {
    return new Promise((resolve, reject) => {
      window.crypto.subtle.importKey(
        'raw',
        this.stringToArrayBuffer(this.getPassphrase()),
        this.privateKeyType,
        false,
        ['deriveBits', 'deriveKey']
      ).then((key) => {
        return window.crypto.subtle.deriveKey({
          name: this.privateKeyType,
          hash: this.sha,
          salt: this.stringToArrayBuffer(this.getSalt()),
          iterations: this.interations
        }, key, {
          name: this.mode,
          length: this.length
        }, false, ['encrypt', 'decrypt']);
      }).then((webKey) => {
        window.crypto.subtle.decrypt(
          {
            name: this.mode,
            length: this.length,
            iv: new Uint8Array(Object.keys(data.iv).map((key) => {
              return data.iv[key];
            }))
          },
          webKey,
          this.decode64ArrayBuffer(data.cipherText)
        ).then((decrypted) => {
          resolve(JSON.parse(this.arrayBufferToString(decrypted)));
        }, (error) => {
          console.log(error);
          reject('Decrypt Private Key Failed');
        });
      }, (error) => {
        console.log(error);
        reject('Decrypt Private Key Failed');
      });
    });
  }

  generateKey() {
    return new Promise((resolve, reject) => {
      window.crypto.subtle.generateKey(
        {
          name: this.type,
          modulusLength: this.privateKeyLength,
          publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
          hash: {
            name: this.sha
          },
        },
        true,
        ['encrypt', 'decrypt']
      ).then((key) => {
        resolve(key);
      }, (error) => {
        console.log(error);
        reject('Generate Key Pair Failed');
      });
    });
  }

  base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  base64url_encode(buffer) {
    return btoa(Array.from(new Uint8Array(buffer), b => String.fromCharCode(b)).join(''))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  importKey(type, key, encrypt = false) {
    return new Promise((resolve, reject) => {
      if (typeof(encrypt) === 'undefined') {
        encrypt = true;
      }

      if (type === 'private' && encrypt) {
        this.decryptKey(key).then((decryptedKey) => {
          this.importKey('private-decrypted', decryptedKey).then((importedKey) => {
            resolve(importedKey);
          }, (error) => {
            reject(error);
          });
        }, (error) => {
          reject(error);
        });
      } else {
        if (key.n && type === 'public') {
          key.n = this.base64url_encode(this.decode64ArrayBuffer(key.n));
        }

        window.crypto.subtle.importKey(
          'jwk',
          key,
          {
            name: this.type,
            hash: {
              name: this.sha
            },
          },
          true,
          [type === 'public' ? 'encrypt' : 'decrypt']
        ).then((importedKey) => {
          resolve(importedKey);
        }, (error) => {
          console.log(error);
          reject('Import ' + (type === 'private' ? 'Private' : 'Public') + ' Key Failed');
        });
      }
    });
  }

  exportKey(type, key) {
    return new Promise((resolve, reject) => {
      window.crypto.subtle.exportKey(
        'jwk',
        key
      ).then((data) => {
        if (type === 'private') {
          this.encryptKey(data).then((encrypted) => {
            resolve(encrypted);
          }, (error) => {
            reject(error);
          });
        } else {
          resolve(data);
        }
      }, (error) => {
        console.log(error);
        reject('Export ' + (type === 'private' ? 'Private' : 'Public') + ' Key Failed');
      });
    });
  }

  encryptData(data, publicKey) {
    const dataToEncrypt = (typeof(data) !== 'string' ? JSON.stringify(data) : data);

    return new Promise((resolve, reject) => {
      // Encrypt in chunks if data exceeds dataLength
      if (dataToEncrypt.length > this.dataLength) {
        const chunks = dataToEncrypt.match(new RegExp('.{1,' + this.dataLength + '}', 'g'));

        const encryptedArr = [];

        let idx = 0;
        const nextChunk = () => {
          if (idx < chunks.length) {
            this.encryptData(chunks[idx], publicKey).then((encryptedChunk) => {
              encryptedArr.push(encryptedChunk);

              idx++;
              nextChunk();
            }).catch((error) => {
              console.log(error);
              reject('Failed to Encrypt Data Chunk');
            });
          } else {
            resolve(encryptedArr);
          }
        };

        nextChunk();
      } else {
        window.crypto.subtle.encrypt(
          {
            name: this.type,
          },
          publicKey,
          this.stringToArrayBuffer(dataToEncrypt)
        ).then((encrypted) => {
          resolve(this.encode64ArrayBuffer(encrypted));
        }, (error) => {
          console.log(error);
          reject('Failed to Encrypt Data');
        });
      }
    });
  }

  decryptData(data, privateKey) {
    return new Promise((resolve, reject) => {
      if (typeof(data) !== 'string') {
        let decryptedString = '';

        let idx = 0;
        const nextChunk = () => {
          if (idx < data.length) {
            this.decryptData(data[idx], privateKey).then((decryptedChunk) => {
              decryptedString += decryptedChunk;

              idx++;
              nextChunk();
            }, (error) => {
              console.log(error);
              reject('Failed to Decrypt Data Chunk');
            });
          } else {
            resolve(decryptedString);
          }
        };

        nextChunk();
      } else {
        window.crypto.subtle.decrypt(
          {
            name: this.type,
          },
          privateKey,
          this.decode64ArrayBuffer(data)
        ).then((decrypted) => {
          resolve(this.arrayBufferToString(decrypted));
        }, (error) => {
          console.log(error);
          reject('Failed to Decrypt Data');
        });
      }
    });
  }
}

export default CryptoService;
