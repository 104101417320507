import React from 'react';

import styles from './styles.module.scss';

const Index = ({ src, width, height, alt, title, type = 'png', className = styles.img }) => {
  const basename = process.env.PUBLIC_URL ? process.env.PUBLIC_URL.trimEnd() : '';

  if (type === 'svg') {
    return (
      <img
        src={basename + '/' + src + '.' + type}
        width={width}
        height={height}
        alt={alt}
        title={title}
        className={className}
      />
    );
  }

  return (
    <picture>
      <source srcSet={basename + '/' + src + '.webp'} type="image/webp"/>
      <source
        srcSet={basename + '/' + src + '.' + type}
        type={'image/' + type}
      />
      <img
        src={basename + '/' + src + '.' + type}
        width={width}
        height={height}
        alt={alt}
        title={title}
        className={className}
      />
    </picture>
  );
};

export default Index;
