import LicenseTypes from './api/LicenseTypes';
import Order from './api/Order';
import Account from './api/Account';
import Notify from './api/Notify';

const Api = {
  Account: Account,
  LicenseTypes: LicenseTypes,
  Order: Order,
  Notify: Notify,
};
export default Api;
