import moment from 'moment-timezone';
import openingHours from "./OpeningHours";

const OpeningHours = {
  days: {
    monday: {
      open: '08:00',
      close: '20:00',
    },
    tuesday: {
      open: '08:00',
      close: '20:00',
    },
    wednesday: {
      open: '08:00',
      close: '20:00',
    },
    thursday: {
      open: '08:00',
      close: '20:00',
    },
    friday: {
      open: '08:00',
      close: '20:00',
    },
    saturday: {
      open: '09:00',
      close: '18:00',
    },
    sunday: {
      open: '10:00',
      close: '16:00',
    }
  },

  daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

  isOpen: () => {
    const now = moment();
    now.tz('Europe/London');
    const open = OpeningHours.days[OpeningHours.daysOfWeek[now.day()].toLowerCase()].open;
    const closed = OpeningHours.days[OpeningHours.daysOfWeek[now.day()].toLowerCase()].close;

    const [openHour, openMinute] = open.split(':');
    const [closedHour, closedMinute] = closed.split(':');

    const openTimeCheck = moment();
    openTimeCheck.tz('Europe/London')
    openTimeCheck.set({
      hour: openHour,
      minute: openMinute,
    });

    const closedTimeCheck = moment();
    closedTimeCheck.tz('Europe/London')
    closedTimeCheck.set({
      hour: closedHour,
      minute: closedMinute,
    });

    return now > openTimeCheck && now < closedTimeCheck;
  },

  isClosingSoon: () => {
    const now = moment();
    const hourFromNow = moment();
    hourFromNow.tz('Europe/London')
    hourFromNow.set({
      hour: parseInt(hourFromNow.format('HH')) + 1
    });
    const closingTime = openingHours.getTimeClosingToday();

    return hourFromNow > closingTime && now < closingTime;
  },

  getTimeClosingToday: () => {
    const now = moment();
    now.tz('Europe/London');
    const closed = OpeningHours.days[OpeningHours.daysOfWeek[now.day()].toLowerCase()].close;
    const [closedHour, closedMinute] = closed.split(':');

    const closedTime = moment();
    closedTime.tz('Europe/London');
    closedTime.set({
      hour: closedHour,
      minute: closedMinute
    });

    return closedTime;
  },

  getTimeOpenToday: () => {
    const now = moment();
    now.tz('Europe/London');
    const open = OpeningHours.days[OpeningHours.daysOfWeek[6 > now.day() ? now.day() : 0].toLowerCase()].open;
    const [openHour, openMinute] = open.split(':');

    const openTime = moment();
    openTime.tz('Europe/London');
    openTime.set({
      hour: openHour,
      minute: openMinute
    });

    return openTime > now ? openTime : false;
  },

  getTimeOpenTomorrow: () => {
    const now = moment();
    now.tz('Europe/London');
    const open = OpeningHours.days[OpeningHours.daysOfWeek[6 > now.day() ? now.day() + 1 : 0].toLowerCase()].open;
    const [openHour, openMinute] = open.split(':');

    const openTime = moment();
    openTime.tz('Europe/London');
    openTime.set({
      hour: openHour,
      minute: openMinute
    });

    return openTime;
  }
};

export default OpeningHours;
