import { useEffect, useState } from 'react';

const Meta = () => {
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');

  useEffect(() => {
    if (metaTitle !== undefined && 0 !== metaTitle.length) {
      document.title = metaTitle;
    }

    const metas = document.getElementsByTagName('meta')
    for (let i = 0, l = metas.length; i < l; i++) {
      const metaName = metas[i].getAttribute('name') || metas[i].getAttribute('property');

      switch (metaName) {
        case 'og:site_name':
        case 'og:title':
        case 'application-name':
          if (metaTitle !== undefined && 0 !== metaTitle.length) {
            metas[i].content = metaTitle;
          }
          break;
        case 'description':
        case 'og:description':
          if (metaDescription !== undefined && 0 !== metaDescription.length) {
            metas[i].content = metaDescription;
          }
          break;
        default:
          break;
      }
    }
  },[metaTitle, metaDescription]);

  return [metaTitle, setMetaTitle, metaDescription, setMetaDescription];
};

export default Meta;
