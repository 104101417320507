import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FontAwesomeIcon from '../../FontAwesomeIcon';

const LoadingSection = ({ loadingText = 'Loading...' }) => {
  return <section className="h-50 pt-5 pb-5">
    <Container className="h-100 pt-5 pb-5">
      <Row className="h-100 align-items-center">
        <Col className="text-center">
          <FontAwesomeIcon icon="spinner" className="fa-spin me-2" />
          {loadingText}
        </Col>
      </Row>
    </Container>
  </section>;
};

export default LoadingSection;
