import Request from '../system/Request';

const Device = {
  get: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/users/me/installs', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  delete: (deviceReference) => {
    return new Promise((resolve, reject) => {
      (new Request()).delete(`/api/users/me/installs/${deviceReference}/delete`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  }
};

export default Device;
