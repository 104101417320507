import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import MainNav from '../MainNav';
import LoginDialog from '../../dialogs/LoginDialog';
import ForgotPasswordDialog from '../../dialogs/ForgotPasswordDialog';
import RegisterDialog from '../../dialogs/RegisterDialog';

import '../../scss/layout/header.scss';

const Header = () => {
  const hasLoaded = useRef(false);
  const matches = useMediaQuery('(max-width: 992px)');
  const [useMobileMenu, setUseMobileMenu] = useState(false);
  const [showDialog, setShowDialog] = useState({
    login: false,
    forgotPassword: false,
    register: false
  });

  useEffect(() => {
    if (matches === useMobileMenu) return;

    setUseMobileMenu(matches);
  }, [matches, useMobileMenu]);

  useEffect(() => {
    if (hasLoaded.current) return;
    hasLoaded.current = true;

    const setHeaderSpace = () => {
      if (undefined === document.getElementById('header') || 0 === document.getElementById('header').offsetHeight) return;

      document.getElementById('root').style.paddingTop = document.getElementById('header').offsetHeight + 'px';
    };

    window.addEventListener('resize', () => {
      setHeaderSpace();
    });

    setHeaderSpace();
  }, []);

  const openLogin = () => {
    setShowDialog({ register: false, forgotPassword: false, login: true });
  };
  const openForgotPassword = () => {
    setShowDialog({ register: false, forgotPassword: true, login: false });
  };
  const openRegister = () => {
    setShowDialog({ register: true, forgotPassword: false, login: false });
  };

  return <>
    {showDialog.login ? <LoginDialog
      closeDialog={() => setShowDialog({ ...showDialog, login: false })}
      openRegister={openRegister}
      openForgotPassword={openForgotPassword}
    /> : ''}
    {showDialog.forgotPassword ? <ForgotPasswordDialog
      closeDialog={() => setShowDialog({ ...showDialog, forgotPassword: false })}
      openLogin={openLogin}
    /> : ''}
    {showDialog.register ? <RegisterDialog
      closeDialog={() => setShowDialog({ ...showDialog, register: false })}
      openLogin={openLogin}
    /> : ''}
    <MainNav
      openLogin={openLogin}
      openRegister={openRegister}
    />
  </>;
};

export default Header;
