const InitOrderStateData = {
  first_name: '',
  last_name: '',
  email: '',
  company_name: '',
  company_address_line_1: '',
  company_address_line_2: '',
  company_address_line_3: '',
  company_county: '',
  company_country: '',
  company_town_city: '',
  company_postal_code: '',
  company_phone_number: '',
  company_reg_number: '',
  company_tax_number: '',
  plans: [],
  currency: 'GBP',
  terms: false,
  business: false,
  vat: false,
  showIncVat: true,
  amount: {
    subtotal: 0,
    order_discount: 0,
    order_configurations: 0,
    volume_discount: 0,
    total: 0
  }
};

export default InitOrderStateData;
