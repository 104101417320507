import LicenseTypeNormalizer from '../normalizer/LicenseTypeNormalizer';
import Request from '../system/Request';

const LicenseTypes = {
  getFromKey: (key, currency) => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/license_types', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then((data) => 0 < data.length ?
          LicenseTypeNormalizer(data, [{
            key: key
          }], currency).then(options => resolve(options[0]))
          :
          reject()
        ).catch((e) => {
        reject(e);
      });
    });
  },

  getFromDataSet: (options, currency) => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/license_types', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then((data) => 0 < data.length ?
            LicenseTypeNormalizer(data, options, currency).then(options => resolve(options))
          :
            reject()
        ).catch((e) => {
          reject(e);
        });
    });
  }
};

export default LicenseTypes;
