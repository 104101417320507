import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import FontAwesomeIcon from '../../FontAwesomeIcon';
import OpeningHours from '../../../data/OpeningHours';
import moment from "moment-timezone";

import styles from './styles.module.scss';

const FindOutMoreDataCentreSection = () => {
  const openingHours = OpeningHours;
  const [date, setDate] = useState(null);

  useEffect(() => {
    const timer = setInterval(()=> {
      const time = moment();
      time.tz('Europe/London');
      setDate(time);
    }, 1000);

    return function cleanup() {
      clearInterval(timer)
    }
  });

  const onCall = () => {
    window.location.href = 'tel:+441206803228';
  };

  return <section className={styles.component}>
    <Container className="pt-4 pb-4">
      <Row className="pt-4 pb-4">
        <Col xs={12} lg={8} xl={8}>
          <h2 className="mt-0 mb-0 white-text">
            <FontAwesomeIcon icon="question-circle" className={'text-tertiary me-2'} />
            Have a <span className={'bolder'}>question</span> for our team?
          </h2>
          <p className="text-tertiary block mt-3 mb-2 fs-5">
            {openingHours.isOpen() ? <>
              Give us a call <span className={'bolder'}>today</span> for more information or to arrange a visit to our data centre.
            </> : <>
              Enquire <span className={'bolder'}>today</span> for more information or to arrange a visit to our data centre.
            </>}<br/>
            <span className="fs-7 white-text">
              ({openingHours.isOpen() ? <>
              we'll be open today until {openingHours.getTimeClosingToday().format('h:mma')}, back tomorrow from {openingHours.getTimeOpenTomorrow().format('h:mma')}
            </> : <>
              our team are available tomorrow from {openingHours.getTimeOpenTomorrow().format('h:mma')}
            </>})
            </span><br />
            <span className="fs-7 text-tertiary">
              <FontAwesomeIcon icon="clock" className={'text-tertiary me-2'} />
              UK GMT Time {date ? '- ' + date.format('h:mm:ssa') : ''}
            </span>
          </p>
        </Col>
        <Col xs={12} lg={4} xl={4} className="text-center text-md-end pt-2">
          {openingHours.isOpen() ? <Button onClick={() => onCall()} size="lg" className="mb-3 fs-6 brand white-text">
            <FontAwesomeIcon icon="phone" className={'me-2'} />
            <b>Call Now</b> +44(0)1206 803 228
          </Button> : <Button as={Link} to="/company/data-centre" size="lg" className="mb-3 fs-6 brand white-text">
            Get In Touch
            <FontAwesomeIcon icon="chevron-right" className={'ms-2'} />
          </Button>}
          <br />
          <Button as={Link} to="/company/data-centre" size="lg" className="fs-6 white black-text">
            More Information
            <FontAwesomeIcon icon="chevron-right" className={'ms-2'} />
          </Button>
        </Col>
      </Row>
    </Container>
  </section>;
};

export default FindOutMoreDataCentreSection;
