import InitOrderStateData from "../data/InitOrderStateData";

export const Order = (OrderState) => {
  let state = OrderState;

  const getState = () => {
    return state;
  };

  const update = (key, value) => {
    state[key] = value;
  };

  const resetState = () => {
    state = { ...InitOrderStateData, ...state };
    state.plans = [];
    state.terms = false;
    state.amount.subtotal = 0;
    state.amount.order_discount = 0;
    state.amount.order_configurations = 0;
    state.amount.volume_discount = 0;
    state.amount.total = 0;
  }

  const updateTotal = () => {
    const plans = state.plans;

    let total = 0;
    let order_discount = 0;
    let order_configurations = 0;
    let volume_discount = 0;

    plans.forEach((plan) => {
      total = total + (plan.retailPrice[state.currency ?? plan.currency] * plan.quantity);
    });

    state.amount.subtotal = total;

    plans.forEach((plan) => {
      if (['sftpclient', 'sftpclient-chrome', 'sftpserver'].includes(plan.product.key)) {
        plan = volumeDiscount(plan);

        volume_discount = volume_discount + plan.volume_discount_price;
      }

      order_configurations = order_configurations + plan.configuration_price;

      if (plan.price[state.currency ?? plan.currency] !== plan.retailPrice[state.currency ?? plan.currency]) {
        plan.price_discount = (plan.retailPrice[state.currency ?? plan.currency] - plan.price[state.currency ?? plan.currency]) * plan.quantity;

        order_discount = order_discount + plan.price_discount;
      }
    });

    total = total - order_discount;
    total = total - volume_discount;
    total = total + order_configurations;

    state.amount.total = total;
    state.amount.order_configurations = order_configurations;
    state.amount.order_discount = order_discount;
    state.amount.volume_discount = volume_discount;

    if (null === state.currency) {
      state.currency = plans[0].currency;
    }
  };

  const volumeDiscount = (plan) => {
    plan.volume_discount_rate = 0;
    plan.volume_discount_price = 0;

    // Don't discount plans with sale prices
    if (plan.price[state.currency ?? plan.currency] !== plan.retailPrice[state.currency ?? plan.currency]) {
      return plan;
    }

    if (
      plan.quantity >= 3 &&
      plan.quantity <= 24
    ) {
      plan.volume_discount_rate = 10;
      plan.volume_discount_price = ((10 / 100) * plan.price[state.currency ?? plan.currency]) * plan.quantity;
    } else if (
      plan.quantity >= 25 &&
      plan.quantity <= 49
    ) {
      plan.volume_discount_rate = 15;
      plan.volume_discount_price = ((15 / 100) * plan.price[state.currency ?? plan.currency]) * plan.quantity;
    } else if (
      plan.quantity >= 50 &&
      plan.quantity <= 74
    ) {
      plan.volume_discount_rate = 20;
      plan.volume_discount_price = ((20 / 100) * plan.price[state.currency ?? plan.currency]) * plan.quantity;
    } else if (
      plan.quantity >= 75 &&
      plan.quantity <= 99
    ) {
      plan.volume_discount_rate = 25;
      plan.volume_discount_price = ((25 / 100) * plan.price[state.currency ?? plan.currency]) * plan.quantity;
    } else if (
      plan.quantity >= 100
    ) {
      plan.volume_discount_rate = 30;
      plan.volume_discount_price = ((30 / 100) * plan.price[state.currency ?? plan.currency]) * plan.quantity;
    }

    return plan;
  };

  return {getState, resetState, update, updateTotal};
};
