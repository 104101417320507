import React from 'react';
import { Button } from 'react-bootstrap';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

import styles from './styles.module.scss';

const Dialog = ({ children, title, closeDialog }) => {
  return <div className={styles.dialog}>
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>{title}</h2>
        <Button onClick={closeDialog} variant="dark">
          <FontAwesomeIcon icon="times" />
        </Button>
      </div>
      <div className={styles.body}>
        <div>
          {children}
        </div>
      </div>
    </div>
  </div>;
}

export default Dialog;
