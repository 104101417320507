import Request from '../system/Request';

const Notify = {
  post: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/notify/website', JSON.stringify({
        title: data.title,
        items: data.items,
        color: data.color,
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Notify;
