const MetaData = (path) => {
  const data = {
    '/': {
      title: `Welcome to Purple IO - We're a UK Data Centre`,
      description: '',
    },

    '/blog': {
      title: 'Latest new and updates from Purple IO',
      description: 'Read all the latest information and news from our team at Purple IO.',
    },

    '/company/about': {
      title: 'About Purple IO',
      description: `We're on a mission to make working life simpler, more pleasant and more productive — for everyone.`,
    },
    '/company/data-centre': {
      title: 'Purple IO Data Centre - Colchester, Essex, UK',
      description: 'Our data centre is located in Colchester (UK), where you will see our private data centre warehouse. We have plenty of dedicated parking spaces for our co-location customers.',
    },
    '/company/careers': {
      title: 'Work at Purple IO',
      description: 'Volunteers, Work Experience, Jobs, and Careers.',
    },
    '/company/customers': {
      title: 'Purple IO Customers',
      description: `Take a look at some of our customers, we have selected the ones that you're most likely to have come across.`,
    },
    '/company/contact': {
      title: 'Contact Purple IO',
      description: `Want to reach out? We'd love to hear from you!`,
    },

    '/chrome': {
      title: 'sFTP Client for Chrome',
      description: 'A file transfer application built for Chrome OS / Chrome Browser.',
    },
    '/chrome/features': {
      title: 'sFTP Client for Chrome Features',
      description: 'View the most popular features of our sFTP Client for Chrome app.',
    },
    '/chrome/pricing': {
      title: 'sFTP Client for Chrome Plans & Pricing',
      description: 'Take a look at our pricing plans for our sFTP Client app for Chrome OS / Chrome Browser.',
    },
    '/chrome/download': {
      title: 'Download sFTP Client for Chrome OS / Browser',
      description: 'Download our sFTP Client app for Chrome OS or Chrome Browser.',
    },
    '/chrome/release': {
      title: 'Release notes for sFTP Client for Chrome',
      description: 'View release dates and read the latest release notes from Purple IO, the only complete sFTP Client for Chrome app.',
    },

    '/client': {
      title: 'sFTP Client for Windows, macOS and Linux',
      description: 'A desktop file transfer application built for Windows, macOS and Linux.',
    },
    '/client/features': {
      title: 'sFTP Client Features',
      description: 'View the most popular and Pro features of our sFTP Client app.',
    },
    '/client/pricing': {
      title: 'sFTP Client Plans & Pricing',
      description: 'Take a look at our pricing plans for our sFTP Client app for Windows, macOS and Linux.',
    },
    '/client/download': {
      title: 'Download sFTP Client for Windows, macOS or Linux',
      description: 'Download our sFTP Client desktop app for Microsoft Windows, Apple macOS or Linux',
    },
    '/client/download/windows': {
      title: 'Download sFTP Client for Windows',
      description: 'Download our sFTP Client desktop app for Microsoft Windows.',
    },
    '/client/download/mac': {
      title: 'Download sFTP Client for macOS',
      description: 'Download our sFTP Client desktop app for Apple macOS.',
    },
    '/client/download/linux': {
      title: 'Download sFTP Client for Linux',
      description: 'Download our sFTP Client desktop app for Linux.',
    },
    '/client/release': {
      title: 'Release notes for sFTP Client',
      description: 'View release dates and read the latest release notes from Purple IO, the only complete sFTP Client app.',
    },

    '/media': {
      title: 'Branding & Media Kit',
      description: 'Download branding and media kits for Purple IO and our apps.',
    },

    '/partners': {
      title: 'Purple IO Partners',
      description: 'Take a look at who we\'ve partnered with.',
    },

    '/legal': {
      title: 'Purple IO Legal Stuff',
      description: `We've put together a compiled list of our important documents for you to review at any time that suits you.`,
    },
    '/legal/abuse': {
      title: 'Purple IO Abuse Policy',
      description: '',
    },
    '/legal/aup': {
      title: 'Purple IO Acceptable Use Policy',
      description: '',
    },
    '/legal/anti-spam': {
      title: 'Purple IO Anti-Spam Policy',
      description: '',
    },
    '/legal/cookies': {
      title: 'Purple IO Cookie Policy',
      description: '',
    },
    '/legal/privacy': {
      title: 'Purple IO Privacy Policy',
      description: '',
    },
    '/legal/sla': {
      title: 'Purple IO Service Level Agreement',
      description: '',
    },
    '/legal/eula': {
      title: 'Purple IO End User License Agreement (EULA)',
      description: '',
    },
    '/legal/terms': {
      title: 'Purple IO Terms & Conditions',
      description: '',
    },
    '/legal/hosting/terms': {
      title: 'Purple IO Hosting Terms & Conditions',
      description: '',
    },
    '/legal/vps/terms': {
      title: 'Purple IO Virtual Private Servers Terms & Conditions',
      description: '',
    },
    '/legal/dedicated/terms': {
      title: 'Purple IO Dedicated Servers Terms & Conditions',
      description: '',
    },
    '/legal/co-location/terms': {
      title: 'Purple IO Co-Location Terms & Conditions',
      description: '',
    },
    '/legal/dpa': {
      title: 'Purple IO Data Protection Agreement',
      description: '',
    },
    '/legal/drp': {
      title: 'Purple IO Disaster Recovery Plan',
      description: '',
    },
    '/legal/gdpr': {
      title: 'Purple IO GDPR',
      description: '',
    },
    '/legal/irp': {
      title: 'Purple IO Incident Response Plan',
      description: '',
    },
    '/legal/pci-dss': {
      title: 'Purple IO PCI-DSS SAQ-A',
      description: '',
    },
    '/legal/security': {
      title: 'Purple IO Security Policy',
      description: '',
    },

    '/server': {
      title: 'sFTP Server for Windows, macOS and Linux',
      description: 'A desktop app built for running FTP and SFTP servers with user management and more.',
    },
    '/server/features': {
      title: 'sFTP Server Features',
      description: 'View the most popular and Pro features of our sFTP Server app.',
    },
    '/server/pricing': {
      title: 'sFTP Server Plans & Pricing',
      description: 'Take a look at our pricing plans for our sFTP Server app for Windows, macOS and Linux.',
    },
    '/server/download': {
      title: 'Download sFTP Server desktop app for Windows, macOS or Linux',
      description: 'Download our sFTP Server desktop app for Microsoft Windows, Apple macOS or Linux',
    },
    '/server/download/windows': {
      title: 'Download sFTP Server for Windows',
      description: 'Download our sFTP Server desktop app for Microsoft Windows.',
    },
    '/server/download/mac': {
      title: 'Download sFTP Server for macOS',
      description: 'Download our sFTP Server desktop app for Apple macOS.',
    },
    '/server/download/linux': {
      title: 'Download sFTP Server for Linux',
      description: 'Download our sFTP Server desktop app for Linux.',
    },
    '/server/release': {
      title: 'Release notes for sFTP Server',
      description: 'View release dates and read the latest release notes from Purple IO, the only complete sFTP Server app.',
    },

    '/security': {
      title: 'Purple IO Security',
      description: `Starting from login's to encryption, find out all you need to know about our security.`,
    },
  };

  return data[path];
};

export default MetaData;
