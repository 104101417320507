import Request from '../system/Request';

const WorkspaceItem = {
  get: (workspace) => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/users/me/items/null', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default WorkspaceItem;
