import Request from '../system/Request';
import WorkspaceItem from './WorkspaceItem';

const Workspace = {
  get: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/users/me/workspaces', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  new: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/users/me/workspace', JSON.stringify({
        owner: data.owner,
        name: data.name,
        description: data.description,
        image: data.image,
        type: data.type,
        team: data.team
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  update: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).put('/api/users/me/workspace/' + data.reference, JSON.stringify({
        owner: data.owner,
        name: data.name,
        description: data.description,
        image: data.image,
        type: data.type,
        team: data.team
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  delete: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).delete('/api/users/me/workspace/' + data.reference, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  Items: WorkspaceItem
};

export default Workspace;
