import Request from "../system/Request";

const Order = {
  get: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/users/me/orders', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  process: (data, paymentMethod) => {
    return new Promise((resolve, reject) => {
      const plan = data.plans[0];

      (new Request()).post('/order/' + plan.key + '/process', JSON.stringify({
        forename: data.first_name,
        surname: data.last_name,
        company: data.company,
        email: data.email,
        paymentMethod: paymentMethod.gateway_name,
        terms: data.terms ? '1' : '0',
        quantity: plan.quantity.toString(),
        currency: data.currency,
        company_name: data.company_name,
        company_reg_number: data.company_reg_number,
        company_vat_number: data.company_vat_number,
        company_address_line_1: data.company_address_line_1,
        company_address_line_2: data.company_address_line_2,
        company_address_line_3: data.company_address_line_3,
        company_town_city: data.company_town_city,
        company_county: data.company_county,
        company_postal_code: data.company_postal_code,
        company_country: data.company_country,
        config: plan.configurations,
        config_amount: data.amount.order_configurations
      }), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
        .then(data => data.redirect_url ? resolve(data.redirect_url) : reject(data))
        .catch(e => reject(e));
    });
  }
};

export default Order;
