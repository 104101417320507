import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const setHeaderSpace = () => {
      if (undefined === document.getElementById('header') || 0 === document.getElementById('header').offsetHeight) return;

      document.getElementById('root').style.paddingTop = document.getElementById('header').offsetHeight + 'px';
    };

    setTimeout(() => {
      window.scrollTo({
        x: 0,
        y: 0
      }, 'smooth');

      setHeaderSpace();
    }, 100);
  }, [pathname]);

  return null;
}
