import React, { useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Dialog from '../';

import styles from './styles.module.scss';

const ForgotPasswordDialog = ({ closeDialog }) => {
  const [data, setData] = useState({
    username: '',
    new_password: '',
    confirm_new_password: ''
  });
  const [errorFields, setErrorFields] = useState([]);

  const updateField = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;

    setData(newData);
    setErrorFields([]);
  };

  return <Dialog title={<>
    <FontAwesomeIcon icon="lock" className="me-3" />
    Forgot Password
  </>} closeDialog={closeDialog}>
    <div className={styles.dialog}>
      <form id={'login_dialog'}>
        <Alert variant="warning" className="p-2 ps-3 pe-3 w-100">
          <Button
            type={'button'}
            href="https://help.purpleio.uk/account-management/forgot-password#why-will-my-data-be-lost-when-resetting-my-password"
            variant="warning"
            target="_blank"
            className="float-end p-1 fs-7"
          >
            Read more
          </Button>
          <strong className="bold d-block mt-1 mb-2">
            <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1"/>
            WARNING
          </strong>
          <p className="mb-0 text-wrap">
            All synced app data on your account will be lost.
          </p>
        </Alert>
        <Row>
          <Col xs={12}>
            <fieldset>
              <label htmlFor="username" className="mb-1 w-100">
                <FontAwesomeIcon icon="envelope" className="me-2"/>
                Email address
              </label>
              <input
                type="email"
                id="username"
                name="username"
                value={data.username}
                onChange={updateField}
                placeholder="i.e. me@example.com"
                onKeyUp={(e) => console.log(e.key)}
                className={errorFields.includes('username') ? styles.input__error : ''}
                autoComplete={'on'}
                required
              />
            </fieldset>
          </Col>
          <Col xs={12} className="mt-3">
            <fieldset>
              <label htmlFor="new_password" className="mb-1 w-100">
                <FontAwesomeIcon icon="lock" className="me-2"/>
                New Password
              </label>
              <input
                type="password"
                id="new_password"
                name="new_password"
                value={data.new_password}
                onChange={updateField}
                placeholder="Enter a mix of numbers, letters and symbols"
                className={errorFields.includes('new_password') ? styles.input__error : ''}
                autoComplete={'off'}
                required
              />
            </fieldset>
          </Col>
          <Col xs={12} className="mt-3">
            <fieldset>
              <label htmlFor="confirm_new_password" className="mb-1 w-100">
                <FontAwesomeIcon icon="lock" className="me-2"/>
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirm_new_password"
                name="confirm_new_password"
                value={data.confirm_new_password}
                onChange={updateField}
                placeholder="Repeat your new password you used above"
                className={errorFields.includes('confirm_new_password') ? styles.input__error : ''}
                autoComplete={'off'}
                required
              />
            </fieldset>
          </Col>
          <Col xs={12} className="mt-3">
            <Button type={'submit'} variant="success" className="w-100">
              <FontAwesomeIcon icon="sign-in" className="me-2"/>
              Recover Account
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  </Dialog>;
}

export default ForgotPasswordDialog;
