import React, { createContext, useEffect, useState } from 'react'
import InitAccountStateData from '../data/InitAccountStateData';
import Cache from '../system/Cache';
import CryptoService from "../system/Crypto";

export const AccountContext = createContext({
  state: InitAccountStateData,
  dispatch: () => null
});

export const AccountProvider = ({ children }) => {
  const crypto = new CryptoService();
  const cache = new Cache();

  const [accountState, setAccountState] = useState(() => {
    const localAccountState = cache.get('account');

    return null === localAccountState ? InitAccountStateData : localAccountState;
  });

  const dispatchAccount = (account) => {
    // Export key pair (if provided) when storing into local storage
    if (
      null !== account?.user &&
      'string' !== typeof account?.user?.key &&
      'string' !== typeof account?.user?.public &&
      null !== account?.user?.key &&
      null !== account?.user?.public
    ) {
      crypto.exportKey('', account.user.public).then((publicKey) => {
        crypto.exportKey('', account.user.key).then((privateKey) => {
          const cacheAccount = { ...account };
          cacheAccount.user.key = btoa(JSON.stringify(privateKey));
          cacheAccount.user.public = btoa(JSON.stringify(publicKey));
          cache.set('account', cacheAccount);
        });
      });
    } else {
      cache.set('account', account);
    }

    setAccountState(account);
  };

  useEffect(() => {
    if (
      null === accountState ||
      null === accountState.user ||
      null === accountState.user.public ||
      null === accountState.user.key
    ) return;

    if (
      'string' !== typeof accountState?.user?.key ||
      'string' !== typeof accountState?.user?.public
    ) return;

    if (0 < accountState.user.key && 0 < accountState.user.public.length) {
      // Import account key pair (if provided) from cached string
      crypto.importKey(
        'private',
        JSON.parse(atob(accountState.user.key))
      ).then((privateKey) => {
        accountState.user.key = privateKey;

        crypto.importKey(
          'public',
          JSON.parse(atob(accountState.user.public))
        ).then((publicKey) => {
          accountState.user.public = publicKey;
        }).catch((error) => {
          console.log(error);
        });
      }).catch((error) => {
        console.log(error);
      });
    }
  });

  return <AccountContext.Provider value={[ accountState, dispatchAccount ]}>
    { children }
  </AccountContext.Provider>;
};
