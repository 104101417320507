import React from 'react';
import { Col, Row } from 'react-bootstrap';

import styles from './styles.module.scss';

const CodeInput = ({ code, updateCode, errorFields, submit }) => {
  const twoStepFields = [1,2,3,4,5,6];

  const updateField = (index, e) => {
    if (e.target.value.match(/^[0-9]$/) || '' === e.target.value) {
      updateCode(index, e.target.value);
    }
  };

  return <Row className="mt-2 mb-2 ps-4 pe-1">
    {twoStepFields.map((field, index) => <>
      <Col xs={2}>
        <input
          type="text"
          id={`two_step_${field}`}
          name="two_step"
          value={code[field - 1]}
          minLength={1}
          maxLength={1}
          onChange={(e) => updateField(field - 1, e)}
          onKeyUp={(e) => {
            if (e.key.match(/^[0-9]$/)) {
              if (index < twoStepFields.length - 1) {
                document.getElementById(`two_step_${field + 1}`).focus();
              } else {
                submit();
              }
            } else if ('Backspace' === e.key && index > 0) {
              document.getElementById(`two_step_${field - 1}`).focus();
            } else if ('Enter' === e.key) {
              submit();
            }
          }}
          className={errorFields.includes('two_step') ? styles.input__error : ''}
          required
        />
      </Col>
    </>)}
  </Row>;
}

export default CodeInput;
