import React from 'react';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import LoadingSection from '../../components/Sections/LoadingSection';

const Loading = () => {
  return <>
    <Header />
    <LoadingSection />
    <Footer />
  </>;
};

export default Loading;
