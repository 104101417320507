export const VatCountries = [
  // United Kingdom
  'GB',

  // Europe
  'AL', 'AD', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'DD', 'EE', 'FO', 'FI', 'FR', 'DE',
  'GI', 'GR', 'GG', 'HU', 'IS', 'IE', 'IM', 'IT', 'JE', 'LV', 'LI', 'LT', 'LU', 'MK', 'MT', 'FX', 'MD',
  'MC', 'ME', 'NL', 'NO', 'PL', 'PT', 'RO', 'RU', 'SM', 'RS', 'CS', 'SK', 'SI', 'ES', 'SJ', 'SE', 'CH',
  'UA', 'SU', 'VA', 'AX'
];

export const EuCountries = [
  'AL', 'AD', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'DD', 'EE', 'FO', 'FI', 'FR', 'DE',
  'GI', 'GR', 'GG', 'HU', 'IS', 'IE', 'IM', 'IT', 'JE', 'LV', 'LI', 'LT', 'LU', 'MK', 'MT', 'FX', 'MD',
  'MC', 'ME', 'NL', 'NO', 'PL', 'PT', 'RO', 'RU', 'SM', 'RS', 'CS', 'SK', 'SI', 'ES', 'SJ', 'SE', 'CH',
  'UA', 'SU', 'VA', 'AX'
];
